import React, { useState } from 'react';
import './signup.scss';
import Logo from '../assets/photo_2_2024-08-17_15-14-53.jpg'; // Update with the actual path to your logo
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons from react-icons
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';

const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [profileImage, setProfileImage] = useState(null); // State for profile image
  const [phone, setPhone] = useState('');

  const navigate = useNavigate();

  const handleSignin = (event) => {
    event.preventDefault();
    navigate("/Login");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // Function to handle image selection
  const handleImageChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setProfileImage(event.target.files[0]); // Update profile image state
    }
  };

  return (
    <div className="signup-container">
      <div className="top">
        <div className="wrapper">
          <img className="logo" src={Logo} alt="Logo" />
        </div>
      </div>
      <div className="form-containerS">
        <h2 className="title">Create an Account</h2>
        <form>
        <div className="image-picker-container">
            <div className="image-preview">
              {profileImage ? (
                <img
                  src={URL.createObjectURL(profileImage)}
                  alt="Profile"
                  className="profileImagePreview"
                />
              ) : (
                <div className="image-placeholder">
                  No Image
                </div>
              )}
            </div>
            <button
              type="button"
              className="chooseImageButton"
              onClick={() => document.getElementById('profileImage').click()}
            >
              Choose Image
            </button>
            <input
              id="profileImage"
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: 'none' }}
            />
          </div>
          <input type="text" className="input-field" placeholder="Full Name" />
          <input type="email" className="input-field" placeholder="Email" />
        
          
          <div className="password-container">
            <input
              type={showPassword ? 'text' : 'password'}
              className="input-field"
              placeholder="Password"
            />
            <button type="button" className="toggle-password" onClick={togglePasswordVisibility}>
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </button>
          </div>
          <div className="password-container">
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              className="input-field"
              placeholder="Confirm Password"
            />
            <button type="button" className="toggle-password" onClick={toggleConfirmPasswordVisibility}>
              {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
            </button>
          </div>
          <button onClick={handleSignin} type="submit" className="signup-button">Sign Up</button>
        </form>
        <p className="footer-text">Already have an account? <a onClick={handleSignin}className="link">Log in</a></p>
      </div>
    </div>
  );
};

export default Signup;
