import React, { useState } from 'react';
import './home.scss';
import Logo from '../assets/photo_2_2024-08-17_15-14-53-removebg-preview.png';
import ProfilePic from '../assets/images.jpg';
import Lottie from 'react-lottie';
import animationData from '../image/HpPJRqAtjC.json';
import animationretrun from '../image/VGjgwMlzJL.json'

const HomePage = () => {
  const [activeTab, setActiveTab] = useState('order'); // Tab state management
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility
  const [modalMessage, setModalMessage] = useState(''); // Modal message

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const defaultOptionsreturn = {
    loop: true,
    autoplay: true,
    animationData: animationretrun,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  // Handle tab switching
  const switchTab = (tabName) => {
    setActiveTab(tabName);
  };

  // Handle form submission
  const handleFormSubmit = (event, type) => {
    event.preventDefault();
    if (type === 'order') {
      setModalMessage('Order Submitted');
    } else if (type === 'return') {
      setModalMessage('Return Order Submitted');
    }
    setIsModalVisible(true); // Show the modal
  };

  // Close modal
  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="home-container">
      <header className="header">
        <div className="logo-container">
          <img className="logo" src={Logo} alt="Logo" />
        </div>
        <nav className="nav-bar">
          <a href="#order-form" className="nav-link">Order</a>
          <a href="#return" className="nav-link">Return</a>
          <a href="#contact" className="nav-link">Contact</a>
        </nav>
        <div className="profile-container">
          <div className="profile-image">
            <img src={ProfilePic} alt="Profile" />
          </div>
          <span className="profile-name">John Doe</span>
        </div>
      </header>

      <section className="hero">
        <div className="hero-content">
          <h1>Your Product Awaits</h1>
          <p>Experience excellence with our top-of-the-line roasted chickens. Easy ordering.</p>
          
        </div>
      </section>

      {/* Tab Bar */}
      <div className="tab-bar">
        <button
          className={`tab ${activeTab === 'order' ? 'active' : ''}`}
          onClick={() => switchTab('order')}
        >
          Order
        </button>
        <button
          className={`tab ${activeTab === 'return' ? 'active' : ''}`}
          onClick={() => switchTab('return')}
        >
          Return
        </button>
      </div>

      <div className="form-container">
        {/* Order Form */}
        <section
          id="order-form"
          className={`form-section ${activeTab === 'order' ? 'active-slide' : ''}`}
        >
          <div className="form-content">
            <h2>Order Now</h2>
            <form onSubmit={(e) => handleFormSubmit(e, 'order')}>
              <div className="form-group">
                <input type="text" placeholder="Company Name" required />
              </div>
              <div className="form-group">
                <input type="text" placeholder="Contact Person" required />
              </div>
              <div className="form-group">
                <input type="email" placeholder="Email" required />
              </div>
              <div className="form-group">
                <input type="tel" placeholder="Phone Number" required />
              </div>
              <div className="form-group">
                <input type="number" placeholder="Quantity" required />
              </div>
              <div className="form-group">
                <textarea placeholder="Order Details" rows="4" required></textarea>
              </div>
              <button type="submit">Place Order</button>
            </form>
          </div>
          <div className="lottie-container">
            <Lottie options={defaultOptions} height={400} width={400} />
          </div>
        </section>

        {/* Return Form */}
        <section
          id="return"
          className={`form-section ${activeTab === 'return' ? 'active-slide' : ''}`}
        >
          <div className="form-content">
            <h2>Return Now</h2>
            <form onSubmit={(e) => handleFormSubmit(e, 'return')}>
              <div className="form-group">
                <input type="text" placeholder="Name" required />
              </div>
              <div className="form-group">
                <input type="text" placeholder="Location" required />
              </div>
              <div className="form-group">
                <input type="email" placeholder="Email" required />
              </div>
              <div className="form-group">
                <input type="tel" placeholder="Phone Number" required />
              </div>
              <div className="form-group">
                <textarea placeholder="Comment" rows="4" required></textarea>
              </div>
              <button type="submit">Submit Return</button>
            </form>
          </div>
          <div className="lottie-container">
            <Lottie options={defaultOptionsreturn} height={400} width={400} />
          </div>
        </section>
      </div>

      {/* Modal */}
      {isModalVisible && (
        <div className="modal">
          <div className="modal-content">
            <h2>{modalMessage}</h2>
            <button className="close-button" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      )}

      <footer id="contact" className="footer">
        <p>For inquiries, contact us at <a href="mailto:info@jetsondoro.com">info@jetsondoro.com</a></p>
      </footer>
    </div>
  );
};

export default HomePage;
